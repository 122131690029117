import React, {Component} from 'react'
import { withRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

import Home from './components/Home';
import Claims from './components/Claims';
import PutOptions from './components/PutOptions';
import Receivables from './components/Receivables';
import Team from './components/Team';
import Media from './components/Media';
import Reviews from './components/Reviews';
import Footer from './components/Footer';

import './App.css';

class App extends Component {
    render() {
      return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          closeButton={false} />

        <Helmet titleTemplate="Cherokee - %s" defaultTitle="Cherokee Acquisition" />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/claims" component={Claims} />
          <Route path="/put-options" component={PutOptions} />
          <Route path="/receivables" component={Receivables} />
          <Route path="/team" component={Team} />
          <Route path="/media" component={Media} />
          <Route path="/reviews" component={Reviews} />
        </Switch>

        <Footer />
      </React.Fragment>
    );
  }
};

export default withRouter(App);
