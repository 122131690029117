import React from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import '../styles/Header.css';
import ContactModal from './ContactModal';

import Modal from 'react-modal';
import * as Scroll from 'react-scroll';

const animateScroll = Scroll.animateScroll;

Modal.setAppElement('#root');

class Header extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      menuActive: false,
      contactActive: false,
      anchorEl: null,
      mobileAnchorEl: null
    };
  }

  handleResourcesClick = event => {
    if (event.currentTarget.id === 'web-resources') {
      this.setState({ anchorEl: event.currentTarget });

    } else {
      this.setState({ mobileAnchorEl: event.currentTarget });
    }
  };

  handleResourcesClose = target => {
    if (target === 'web-resources') {
      this.setState({ anchorEl: null });

    } else {
      this.setState({ mobileAnchorEl: null });
    }
  };

  toggleMenu = () => {
    this.setState(prevState => ({ menuActive: !prevState.menuActive }));
  };

  openContact = () => {
    this.setState({ contactActive: true });
  };

  closeContact = () => {
    this.setState({ contactActive: false });
  };

  render() {
    const { isHome } = this.props;
    let customStyles = {
      content: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundImage: isHome
          ? 'linear-gradient(rgb(0, 0, 120, 0.9), rgb(0, 0, 120, 0.6))'
          : 'linear-gradient(rgb(170, 170, 170, 0.9), rgb(170, 170, 170, 0.6))',
        overflow: 'auto',
        outline: 'none',
        padding: '20px',
        border: 'none',
        borderRadius: '0',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around'
      },
      overlay: {}
    };
    // console.log('bootstrap: ', bootstrap.Dropdown.Menu)
    const menuSrc = isHome
      ? './assets/icons/menu_white.png'
      : './assets/icons/menu_grey.svg';

    const modalLinkClass = isHome ? 'modal-link' : 'modal-link-grey';

    return (
      <React.Fragment>
        {!isHome && (
        <div id="header">
          <MediaQuery query="(min-width: 769px)">
            <div className="brand-name">
              <div className="brand-name">
                <Link to="/">CHEROKEE ACQUISITION</Link>
              </div>             
            </div>
            <div className="link-container">
              <Link to="/claims" className="link">
                Claims
              </Link>
              <Link to="/put-options" className="link">
                Vendor Put Options
              </Link>
              <Link to="/receivables" className="link">
                Receivables Finance
              </Link>
              <Link to="/team" className="link">
                Team
              </Link>
              <Link to="/media" className="link">
                Media
              </Link>
              <Link to="/reviews" className="link">
                Reviews
              </Link>
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width: 768px)">
              <div className="brand-name">
                <Link to="/">CHEROKEE ACQUISITION</Link>
              </div>
            {!this.state.menuActive && (
              <img src={menuSrc} onClick={this.toggleMenu} />
            )}
          </MediaQuery>
        </div>
        )} 

        {isHome && (
              <div id="header" className="home-header">
              <MediaQuery query="(min-width: 769px)">
                <div className="brand-name">
                  <div className="brand-name home-logo">
                    <Link to="/"><img src="./assets/images/cherokee-logo.png" /></Link>
                  </div>
                </div>
                <div className="link-container">
                  <Link to="/claims" className="link">
                    Claims
                  </Link>
                  <Link to="/put-options" className="link">
                    Vendor Put Options
                  </Link>
                  <Link to="/receivables" className="link">
                    Receivables Finance
                  </Link>
                  <Link to="/team" className="link">
                    Team
                  </Link>
                  <Link to="/media" className="link">
                    Media
                  </Link>
                  <Link to="/reviews" className="link">
                    Reviews
                  </Link>
                </div>
              </MediaQuery>
              <MediaQuery query="(max-width: 768px)">
                  <div className="brand-name home-logo">
                    <img src="./assets/images/cherokee-logo.svg" />
                  </div>
                {!this.state.menuActive && (
                  <img src={menuSrc} onClick={this.toggleMenu} />
                )}
              </MediaQuery>
            </div>
        )}


        <Modal
          isOpen={this.state.menuActive}
          onRequestClose={this.toggleMenu}
          style={customStyles}
          className="header-modal"
        >
          <img src="./assets/icons/close_white.png" onClick={this.toggleMenu} />
          <div className="modal-link-container">
            <Link
              to="/"
              className={modalLinkClass}
              onClick={animateScroll.scrollToTop}
            >
              Home
            </Link>
            <Link
              to="/claims"
              className={modalLinkClass}
              onClick={animateScroll.scrollToTop}
            >
              Claims
            </Link>
            <Link
              to="/put-options"
              className={modalLinkClass}
              onClick={animateScroll.scrollToTop}
            >
              Put Options
            </Link>
            <Link
              to="/receivables"
              className={modalLinkClass}
              onClick={animateScroll.scrollToTop}
            >
              Receivables
            </Link>
            <Link
              to="/team"
              className={modalLinkClass}
              onClick={animateScroll.scrollToTop}
            >
              Team
            </Link>
            <Link
              to="/resources"
              className={modalLinkClass}
              onClick={animateScroll.scrollToTop}
            >
              Resources
            </Link>
            {/* <div id='mobile-resources' className={modalLinkClass} onClick={this.handleResourcesClick}>Resources</div>
                        <Menu className='resource-menu' anchorEl={this.state.mobileAnchorEl} open={!!this.state.mobileAnchorEl} onClose={() => this.handleResourcesClose('mobile-resources')}>
                            <MenuItem className='resource-item' onClick={() => {window.open('https://medium.com/@jellyroll505/claims-market-f8cddd541f20', '_blank'); this.handleResourcesClose('mobile-resources')}}>Claims Market Whitepaper (3.18.2019)</MenuItem>
                            <MenuItem className='resource-item' onClick={() => {window.open('https://www.bloomberg.com/news/articles/2018-12-11/sears-suppliers-offload-trade-claims-amid-cash-shortage-concerns', '_blank'); this.handleResourcesClose('mobile-resources')}}>Sears Suppliers Sell Trade Claims Amid Cash Shortage Concern (12.11.2018)</MenuItem>
                        </Menu> */}
          </div>
        </Modal>
        <ContactModal
          isOpen={this.state.contactActive}
          onRequestClose={this.closeContact}
        />
      </React.Fragment>
    );
  }
}

export default Header;

// className={position === 'toolkit' || position === 'indexbuilder' ? 'active' : ''}
// componentClass={Link} active={position === 'toolkit'}
